import React from 'react'
import { graphql, PageProps } from 'gatsby'

// Components
import SEO, { SeoProps } from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

export interface PageContextProps {
  exists: boolean
  title: string
  translations?: any
  locale: {
    locale: string
  }
  languages: []
}

export const PageContext = React.createContext({
  exists: false,
  title: '',
  translations: [],
  locale: {
    locale: 'nl_NL',
  },
  languages: [],
})

interface PageTemplateProps extends Omit<PageProps, 'data'> {
  data: {
    site: {
      languages: []
    }
    page: {
      databaseId: number
      title?: string
      path?: string
      seo: SeoProps
      flexContent: GatsbyTypes.Maybe<GatsbyTypes.WpFragment_FlexFragment>
      translations?: any
      locale: {
        locale: string
      }
    }
  }
  prefix?: string
}

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: {
    site,
    page: { title = '', flexContent, seo, translations, locale },
  },
  location,
  pageContext,
  prefix = 'Enviroment_Flexcontent',
}) => (
  <Layout locale={locale.locale} translations={translations}>
    <SEO seo={seo} />
    <FlexContentHandler
      prefix={prefix}
      fields={flexContent}
      location={location}
      pageContext={pageContext}
    />
  </Layout>
)

export const pageQuery = graphql`
  query enviromentById($pageId: String!) {
    page: wpEnviroment(id: { eq: $pageId }) {
      ...generalEnviromentFragment
    }

    site: wp {
      ...generalMultilanguageFragment
    }
  }
`

export default PageTemplate
